import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import Home from './components/Home'
import TermsOfUse from './components/TermsOfUse'
import PrivacyPolicy from './components/PrivacyPolicy'
import PageNotFound from './components/PageNotFound'
import './index.css'

const App = () => {
  return (
    
      <Router>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/termsofuse' element={<TermsOfUse />} />
          <Route exact path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route exact path='/*' element={<PageNotFound />} />
        </Routes>
      </Router>
    
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(<App />)


/*
<div>
      <Router>
        <Routes>
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </div>
*/