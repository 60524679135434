import { Link } from 'react-router-dom'
import Logo from './Logo.svg'
import { useLocation } from 'react-router-dom'

const Navbar = () => {
    const linkClass = "block sm:my-auto sm:m-2 py-4 px-4 text-gray-700 rounded hover:bg-gray-200"
    const active = "block sm:m-auto sm:m-2 py-4 px-4 text-gray-700 bg-gray-300 rounded"
    const currentUrl = useLocation()

    return (
        <nav className="bg-gray-100 border-gray-200 p-10 px-2 sm:px-4 py-2.5 rounded">
            <div class="relative flex flex-wrap justify-around sm:h-20 sm:justify-between items-center mx-auto">
                <a href="https://www.autograb.com.au/">
                    <img class="h-8 w-full my-8 md:my-1 sm:w-60" src={Logo} alt="324" />
                </a>
                <div class="flex flex-row list-none">
                    <a href="/" class={linkClass}>
                        <h4>Home</h4>
                    </a>
                    <a href="/termsofuse" class={currentUrl.pathname === '/termsofuse' ? active : linkClass}>
                        <h4>Terms of Use</h4>
                    </a>
                    <a href="privacypolicy" class={currentUrl.pathname === '/privacypolicy' ? active : linkClass}>
                        <h4>Privacy Policy</h4>
                    </a>
                </div>
            </div>
        </nav>
    )
}

export default Navbar