import { React, useEffect } from "react"
import { Link } from 'react-router-dom'
import Logo from './Logo.svg'
import Footer from './Footer'

const Home = () => {
    useEffect(() => {
        document.title = "AutoGrab | Legal"
    }, [])

    return (
        <div>
            <div class="grid lg:grid-cols-2 xl:grid-cols-5 xl:divide-x-[2px]">
                <div class="flex md:min-h-[500px] justify-center xl:col-span-2">
                    <span class="my-auto">
                    <a href="https://www.autograb.com.au/">
                        <img class="h-10 my-20 md:h-14 lg:h-16 w-full" src={Logo} alt="324" />
                    </a>
                    </span>
                </div>
                <div class="md:min-h-[200px] xl:col-span-3 my-auto">
                    <p class="flex mx-8 text-lg sm:my-10 justify-around font-light text-gray-500 lg:mb-8 sm:text-lg lg:text-xl xl:text-2xl dark:text-gray-400">
                        Click to view our Terms of Use and Privacy Policy.
                    </p>
                    <div class="my-10 col-span-1 flex justify-center">
                        <Link to='/termsofuse' class="inline-flex items-center mx-5 px-5 py-3 text-base font-medium text-center bg-ag-blue text-white border border-gray-300 rounded-md hover:bg-blue-700">
                            Terms of Use
                        </Link>
                        <Link to='/privacypolicy' class="inline-flex items-center mx-5 px-5 py-3 text-base font-medium text-center bg-ag-blue text-white border border-gray-300 rounded-md hover:bg-blue-700">
                            Privacy Policy
                        </Link>
                    </div>
                </div>
                <div class="bg-gray-100 h-fit sm:h-screen md:col-span-2 xl:col-span-5">
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Home

/*

<div>
            <div class="grid max-w-screen-xl py-8 mx-auto xl:gap-0 lg:grid-cols-12">
                <div class="mr-auto place-self-center lg:col-span-7">
                    <img class="h-10 md:h-12 w-full my-8 " src={Logo} alt="324" />
                        <p class="max-w-2xl my-10 mx-5 font-light text-gray-500 lg:mb-8 sm:text-lg lg:text-xl dark:text-gray-400">
                            Click to view our Terms of Use and Privacy Policy.
                        </p>
                        <div class="flex justify-around">
                        <Link to='/privacypolicy' class="inline-flex items-center px-5 py-3 text-base font-medium text-center bg-ag-blue text-white border border-gray-300 rounded-md hover:bg-blue-700">
                            Privacy Policy
                        </Link>
                        <Link to='/termsofuse' class="inline-flex items-center px-5 py-3 text-base font-medium text-center bg-ag-blue text-white border border-gray-300 rounded-md hover:bg-blue-700">
                            Terms of Use
                        </Link>
                        </div>
                </div>
            </div>
            <Footer />
        </div>

*/

/*

<div class="grid sm:grid-cols-3">
                <div class="my-5 sm:col-span-1">
                    <img class="h-12 w-full my-8" src={Logo} alt="324" />
                </div>
                <div clas="col-span-1">
                    <p class="flex mx-8 text-lg sm:my-10 justify-around font-light text-gray-500 lg:mb-8 sm:text-lg lg:text-xl dark:text-gray-400">
                        Click to view our Terms of Use and Privacy Policy.
                    </p>
                </div>
                <div class="my-10 col-span-1 flex justify-around">
                    <Link to='/privacypolicy' class="inline-flex items-center px-5 py-3 text-base font-medium text-center bg-ag-blue text-white border border-gray-300 rounded-md hover:bg-blue-700">
                        Privacy Policy
                    </Link>
                    <Link to='/termsofuse' class="inline-flex items-center px-5 py-3 text-base font-medium text-center bg-ag-blue text-white border border-gray-300 rounded-md hover:bg-blue-700">
                        Terms of Use
                    </Link>
                </div>
            </div>

*/

