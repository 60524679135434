import Logo from './Logo.svg'
import NavBar from './NavBar'
import { useEffect } from 'react'
import Footer from './Footer'

const TermsOfUse = () => {
    useEffect(() => {
        document.title = "AutoGrab | Terms of Use"
    }, [])

    return (
        <div>
        <NavBar />
        <div class="sm:max-w-[60%] mx-6 sm:mx-auto sm:pt-5 mb-12">
            <h1 class="text-3xl p-3 mt-6 text-center font-bold">Terms of Use</h1>
            <br />
            <p>
                By accessing or using the website, services or tools offered by Auto Grab Pty Ltd ABN 79 638 468
                569 <strong>(Auto Grab, us, we, our)</strong>, you agree to these terms and conditions <strong>(Terms)</strong> and any other
                terms and conditions notified to you on the AutoGrab website or otherwise from time to time.
            </p>
            <br />
            <p>
                If you have logged into an account to access the website, services or tools as a user of a package
                with AutoGrab, you are bound by the additional terms and conditions entered into by you, your
                business or your employer with AutoGrab.
            </p>
            <br />
            <p>
                <strong>
                    AutoGrab is an independent business and does not represent that it is affiliated with any other
                    marketplace.
                </strong>
            </p>
            <br />
            <p>
                AutoGrab makes available information, vehicle data and other content on its website (Material).
                AutoGrab does not warrant that Material on its website or information received through using its
                website, services or tools is accurate or complete. You are responsible for assessing and verifying
                the accuracy of such Material and information and rely on it at your own risk.
            </p>
            <br />
            <p>
                AutoGrab has no control over and does not warrant the quality, safety or standard of vehicles listed
                on this Website.
            </p>
            <br />
            <p>
                By using our website, services and tools, you agree to the AutoGrab Privacy Policy which outlines
                how AutoGrab collects, uses and discloses your personal information.
            </p>
            <br />
            <p>
                If you have any queries, please contact us on the "Contact" details provided on our website.
            </p>
            <br />
            <p>
                <span class="font-bold">Dated: 16 August 2022</span>
            </p>
        </div>
        <div class="bg-gray-100 h-fit sm:col-span-2">
                    <Footer />
                </div>
        </div>
    )
}

export default TermsOfUse

//AG LOGO
/*
    < div >
    <img class="h-8 w-full my-8 md:my-1 sm:w-60" src={Logo} alt="324" />
            </div >
            */
