import React from 'react'
import Logo from './Logo.svg'

const Footer = () => {
    return (
        <footer class="p-4 sm:p-6">
            <div class="md:flex md:justify-around">
                <div class="grid sm:grid-cols-3 gap-8 sm:gap-20">
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Social Media</h2>
                        <ul class="text-gray-600 dark:text-gray-400">
                        <li class="my-3">
                                <a href="https://docs.autograb.com.au/" class="hover:text-ag-blue">API Documentation</a>
                            </li>
                            <li class="my-3">
                                <a href="https://www.autograb.com.au/jobs" class="hover:text-ag-blue">Jobs</a>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Contact</h2>
                        <ul class="text-gray-600 dark:text-gray-400">
                            <li class="my-3">
                                <a href="mailto:contact@autograb.com.au" class="hover:text-ag-blue">contact@autograb.com.au</a>
                            </li>
                            <li class="my-3">
                                <a href="tel:1800-531-718" class="hover:text-ag-blue">1800 531 718</a>
                            </li>
                            <li class="my-3">
                                <p>Level 1, 24-26 Cubitt St<br />Cremorne 3121</p>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Social Media</h2>
                        <ul class="text-gray-600 dark:text-gray-400">
                            <li class="my-3">
                                Follow us on LinkedIn to stay up to date
                            </li>
                            <li class="my-3">
                                <a href="https://au.linkedin.com/company/autograb" class="hover:text-ag-blue">LinkedIn</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </footer>

    )
}

export default Footer