import NavBar from './NavBar'
import { useEffect } from 'react'
import Footer from './Footer'

const PrivacyPolicy = () => {
    useEffect(() => {
        document.title = "AutoGrab | Privacy Policy"
    }, [])

    return (
        <div>
            <NavBar />
            <div class="sm:max-w-[60%] mx-6 sm:mx-auto sm:pt-5 mb-12">
                <div>
                    <h1 class="text-3xl p-3 mt-6 text-center font-bold">Privacy Policy</h1>
                    <br />
                    <p>
                        Auto Grab Pty Ltd ABN 79 638 468 569 and its related bodies corporate (Auto Grab, us, we, our)
                        takes your privacy seriously and is committed to responsible privacy practices.
                    </p>
                    <br />
                    <p>
                        We seek to comply with our obligations under the Privacy Act 1988 (Cth) (Privacy Act). This
                        Privacy Policy describes how we collect, use, disclose, store, and handle your personal
                        information.
                    </p>
                </div>
                <br />
                <div>
                    <h2 class="text-xl font-bold">What is personal information?</h2>
                    <br />
                    <p>
                        In this Privacy Policy, personal information has the meaning set out in the Privacy Act. In general
                        terms, personal information is information (whether fact or opinion) about an individual who is
                        identified or reasonably identifiable.
                    </p>
                    <br />
                    <p>
                        Some types of personal information are designated as sensitive information, which are subject to
                        additional protection under the Privacy Act. For example, these can include information about your
                        health.
                    </p>
                </div>
                <br />
                <div>
                    <h2 class="text-xl font-bold">What types of personal information do we collect?</h2>
                    <br />
                    <p>
                        The types of personal information we collect about you will depend on the purpose for which the
                        personal information is collected. This can include:
                    </p>
                    <br />
                    <ul class="list-disc pl-10 space-y-3">
                        <li>in the case of users - your name, billing or shipping address, email address, telephone
                            number(s), and payment information (including credit card information or alternative
                            payment method account information);
                        </li>
                        <li>
                            in the case of vendors - your first name and mobile number;
                        </li>
                        <li>
                            if you have requested to receive news about exclusive offers, promotions, or events - your
                            name, mailing or street address, email address, and telephone number(s);
                        </li>
                        <li>
                            if you have contacted our customer service unit to make a complaint, provide feedback,
                            submit an enquiry or request a call-back - your name, mailing or street address, email
                            address, and telephone number(s);
                        </li>
                        <li>
                            in the case of prospective employees or contractors - information contained in your
                            application or résumé, recorded during any interview, or obtained through any pre-
                            employment checks, and government-issued identifiers such as tax file numbers; and
                        </li>
                        <li>
                            in the case of our suppliers and distributors - your name, mailing or street address, email
                            address, and telephone number(s).
                        </li>
                    </ul>
                    <br />
                    <p>
                        We do not collect sensitive information.
                    </p>
                    <br />
                    <p>
                        We also collect technical information and general analytics, such as web browser type and
                        browsing preferences, Internet service provider, referring/exit pages, and date/time stamps, IP
                        address, time zone, and geolocation data (if applicable) arising from your use of our website.
                    </p>
                </div>
                <br />
                <div>
                    <h2 class="text-xl font-bold">How do we collect personal information?</h2>
                    <br />
                    <p>
                        We collect your personal information directly from you, including when you:
                    </p>
                    <br />
                    <ul class="list-disc pl-10 space-y-3">
                        <li>
                            access or use our website;
                        </li>
                        <li>
                            access or use our products or services;
                        </li>
                        <li>
                            sign up to receive news and exclusive offers, promotions, or events;
                        </li>
                        <li>
                            enter surveys, competitions, promotions or requesting information or material from us;
                        </li>
                        <li>
                            make inquiries about us or our products or services or otherwise communicate with us by
                            email, by telephone, in person, via a website or otherwise; and
                        </li>
                        <li>
                            apply to work with us or are engaged by us as a contractor.
                        </li>
                    </ul>
                    <br />
                    <p>
                        Where it is reasonable and practicable to do so, we will only collect personal information about you
                        from you directly and not from third parties. In limited circumstances, we may collect personal
                        information about you from publicly available sources (such as the Internet) and from third parties
                        (such as mutual contacts, or if someone contacts us on your behalf, or your referees provided
                        during the recruitment process if you apply for a job with us). We may also collect personal
                        information through third parties such as our users, service providers, through promotional and
                        marketing activities, and from publicly available sources of information.
                    </p>
                    <br />
                    <p>
                        We also use the following technologies to collect technical information and general analytics:
                    </p>
                    <br />
                    <ul class="list-disc pl-10 space-y-3">
                        <li>
                            cookies, which are data files that are placed on your device and often include an
                            anonymous unique identifier. For more information about cookies, and how to disable
                            cookies, visit <a href='http://www.allaboutcookies.org'>http://www.allaboutcookies.org</a>;
                        </li>
                        <li>
                            log files, which track actions occurring on our website; and
                        </li>
                        <li>
                            web beacons, tags, and pixels, which are electronic files used to record information about
                            how you browse our website.
                        </li>
                    </ul>
                    <br />
                    <p>
                        By using our website, you are consenting to the use of these technologies in accordance with this
                        Privacy Policy.
                    </p>
                </div>
                <br />
                <div>
                    <h2 class="text-xl font-bold">Can you choose not to disclose your personal information?</h2>
                    <br />
                    <p>
                        If you contact us to make a general enquiry about us or our business, you do not have to identify
                        yourself or provide any personal information. Alternatively, you can also notify us that you wish to
                        deal with us using a pseudonym.
                    </p><p>
                        However, if we are not able to collect personal information about you, we may not be able to
                        provide you with the information or assistance you require. For example, we will not be able to
                        send you information you have requested if you have not provided us with a valid email address or
                        telephone number.
                    </p>
                </div>
                <br />
                <div>
                    <h2 class="text-xl font-bold">How do we use your personal information?</h2>
                    <br />
                    <p>
                        In general, we use your personal information for purposes connected with our business.
                    </p>
                    <br />
                    <p>
                        Some of the specific purposes for which we use your personal information are as follows:
                    </p>
                    <br />
                    <ul class="list-disc pl-10 space-y-3">
                        <li>
                            to provide our products and services to our users or to receive goods or services from third
                            parties;
                        </li>
                        <li>
                            to enable the proper operation and functionality of our products and services;
                        </li>
                        <li>
                            to verify your identity (for example, if you request access to the personal information we
                            holds about you);
                        </li>
                        <li>
                            to consider you for a job at Auto Grab (whether as an employee or contractor) or other
                            relationships with us;
                        </li>
                        <li>
                            to communicate with you and to address any issues or complaints that we or you may have
                            regarding our relationship;
                        </li>
                        <li>
                            for direct marketing purposes (see the "Direct marketing" section below); and
                        </li>
                        <li>
                            to contact you regarding the above, including via electronic messaging such as SMS and
                            email, by mail, by phone or in any other lawful manner.
                        </li>
                    </ul>
                    <br />
                    <p>
                        We may also use or disclose your personal information for our administrative, marketing (including
                        direct marketing), planning, product or service development, quality control, survey and research
                        purposes and for other purposes to which you have consented, or as otherwise permitted or
                        required by law.
                    </p>
                    <br />
                    <p>
                        Technical information and general analytics is used for the purpose of gauging visitor traffic, trends
                        and delivering personalised content to you while you are on our website, and to improve our
                        website and our products and services.
                    </p>
                </div>
                <br />
                <div>
                    <h2 class="text-xl font-bold">To whom do we disclose personal information?</h2>
                    <br />
                    <p>
                        We may disclose your personal information to third parties in connection with the purposes
                        described above (see the "How do we use your personal information?"" section).
                    </p>
                    <br />
                    <p>
                        This may include disclosing your personal information to the following types of third parties:
                    </p>
                    <br />
                    <ul class="list-disc pl-10 space-y-3">
                        <li>
                            our related companies;
                        </li>
                        <li>
                            any potential third party acquirer of our business or assets, and advisors to that third party;
                        </li>
                        <li>
                            our professional advisers (such as lawyers, accountants or auditors) and insurers;
                        </li>
                        <li>
                            our employees, contractors and third party service providers who assist us in performing
                            our functions and activities e.g. payment systems operators and financial institutions, cloud
                            service providers, data storage providers, shipping companies, telecommunications
                            providers and IT support services providers;
                        </li>
                        <li>
                            organisations authorised by us to conduct promotional, research or marketing activities;
                        </li>
                        <li>
                            third parties to whom you have authorised us to disclose your information (e.g. referees);
                            and
                        </li>
                        <li>
                            any other person as required or permitted by law.
                        </li>
                    </ul>
                    <br />
                    <p>
                        If we disclose your personal information to third parties we will use reasonable commercial efforts
                        to ensure that such third parties only use your personal information as reasonably required for the
                        purpose of disclosure and in a manner consistent with applicable laws, for example (where
                        commercially practical) by including suitable privacy and confidentiality clauses in our agreement
                        with a third party service provider to which we disclose your personal information.
                    </p>
                    <br />
                </div>
                <div>
                    <h2 class="text-xl font-bold">Direct marketing communications</h2>
                    <br />
                    <p>
                        We will only send you direct marketing communications (either through mail, SMS or email),
                        including offers any news and exclusive offers, promotions, or events, where you have consented
                        to do so.
                    </p>
                    <br />
                    <p>
                        You may opt-out of receiving direct marketing communications at any time by contacting us or by
                        using opt-out facilities provided in the direct marketing communications.
                    </p>
                    <br />
                </div>
                <div>
                    <h2 class="text-xl font-bold">Does personal information leave Australia?</h2>
                    <br />
                    <p>
                        We store personal information in servers located in Australia. We may disclose your personal
                        information to overseas recipients, such as to service providers located overseas (including Heap
                        Analytics and Google Cloud), in order to provide its products and services and to obtain services.
                        It is not practical for us to list every country where such overseas recipients may be located,
                        however such countries are likely to include the USA and the Philippines.
                    </p>
                    <br />
                    <p>
                        Except where an exception applies under the Privacy Act or other relevant legislation, we will take
                        commercially reasonable steps to ensure that overseas recipients to whom we disclose personal
                        information do not breach the Australian Privacy Principles stated in the Privacy Act in relation to
                        such information.
                    </p>
                    <br />
                </div>
                <div>
                    <h2 class="text-xl font-bold">How do we protect your personal information?</h2>
                    <br />
                    <p>
                        We will take reasonable precautions to protect your personal information from misuse, loss, or
                        unauthorised access, modification or disclosure. However, as the Internet is inherently insecure,
                        we cannot guarantee the security of transmission of personal information you disclose to us online.
                        Accordingly, you transmit your personal information to us online at your own risk.
                    </p>
                    <br />
                    <p>
                        Please notify us immediately if you become aware of any breach of security.
                    </p>
                    <br />
                </div>
                <div>
                    <h2 class="text-xl font-bold">How long do we keep your personal information?</h2>
                    <br />
                    <p>
                        Generally, we will retain your personal information for the period necessary for the purposes for
                        which your personal information was collected (as outlined in this Privacy Policy) unless a longer
                        retention period is required by law or if it is reasonably necessary for us to comply with our legal
                        obligations, resolve a dispute or maintain security.
                    </p>
                    <br />
                    <p>
                        When personal information is no longer required, we will take reasonable steps to delete the
                        personal information from our systems or de-identify the personal information. We will only retain
                        technical information for 90 days from the date of collection.
                    </p>
                    <br />
                </div>
                <div>
                    <h2 class="text-xl font-bold">How can you access and correct your personal information?</h2>
                    <br />
                    <p>
                        You may request access to any personal information we hold about you at any time by contacting
                        us. We will provide access to that information in accordance with the Privacy Act, subject to any
                        exemptions that may apply. We may charge an administration fee in limited circumstances, for
                        example if we are required to.
                    </p>
                    <br />
                    <p>
                        If you believe that personal information we hold about you is incorrect, incomplete or inaccurate,
                        then you may request us to amend it by contacting us.
                    </p>
                    <br />
                </div>
                <div>
                    <h2 class="text-xl font-bold">What is the process for complaining about a breach of privacy?</h2>
                    <br />
                    <p>
                        If you have any questions, concerns or complaints about our collection, use, disclosure or
                        management of your personal information, please contact us in writing using the contact details
                        below.
                    </p>
                    <br />
                    <p>
                        We will make inquiries and your complaint will be assessed by an appropriate person with the aim
                        of resolving any issue in a timely and efficient manner.
                    </p>
                    <br />
                    <p>
                        If you are unsatisfied with the outcome, we will advise you about further options including, if
                        appropriate, review by the Privacy Commissioner within the Office of the Australian Information
                        Commissioner.
                    </p>
                    <br />
                </div>
                <div>
                    <h2 class="text-xl font-bold">Exemptions and inconsistency with law</h2>
                    <br />
                    <p>
                        Where local laws allow for an exemption to compliance with certain legal obligations (for example,
                        the employee records exemption), we may rely on such an exemption.
                    </p>
                    <br />
                    <p>
                        This Privacy Policy will not apply to the extent that it is inconsistent with any applicable law.
                    </p>
                    <br />
                </div>
                <div>
                    <h2 class="text-xl font-bold">Changes to this Privacy Policy</h2>
                    <br />
                    <p>
                        We may change this Privacy Policy from time to time at our discretion. Amendments to this Privacy
                        Policy will be posted on our website. Your continued dealings with us, for example use of its
                        websites, products or services, will signify your agreement to this Privacy Policy as amended.
                    </p>
                    <br />
                </div>
                <div>
                    <h2 class="text-xl font-bold"> How to contact us</h2>
                    <br />
                    <p>
                        If you have a query, concern or complaint about the manner in which your personal information has
                        been collected or handled by us or would like to request access to or correction of the personal
                        information we hold about you, please contact using the details provided below:
                    </p>
                    <br />
                    <p>
                        Auto Grab
                        <br />
                        Attention: Privacy Officer
                        <br />
                        204 New Street
                        <br />
                        Brighton VIC 3186
                        <br />
                        contact@autograb.com.au
                    </p>
                    <br />
                    <p class="font-bold">
                        Last updated: 16 August 2022
                    </p>
                </div>
            </div >
            <div class="bg-gray-100 h-fit sm:col-span-2">
                    <Footer />
                </div>
        </div>
    )
}

export default PrivacyPolicy

//AG LOGO
/*
    < div >
    <img class="h-8 w-full my-8 md:my-1 sm:w-60" src={Logo} alt="324" />
            </div >
            */